import type { UIKitColorPalette } from "#types";

export const palette: UIKitColorPalette = {
  red200: "#8F000A",
  red150: "#AD0100",
  red125: "#C70100",
  red100: "#ED0100",
  red60: "#F46463",
  charcoal100: "#3D3935",
  charcoal75: "#6E6B68",
  charcoal50: "#9E9C9A",
  charcoal20: "#D8D7D7",
  charcoal10: "#ECECEC",
  charcoal5: "#F7F7F7",
  lime200: "#AEBA00",
  lime150: "#BECC00",
  lime100: "#D0DF00",
  lime20: "#F0F3C6",
  lime10: "#F3F4DE",
  gray100: "#222121",
  gray95: "#2D2C2C",
  gray90: "#383737",
  gray85: "#414040",
  gray80: "#515050",
  gray60: "#737373",
  gray35: "#B2B2B2",
  gray10: "#D9D9D9",
  gray5: "#F4F4F4",
  blue200: "#004157",
  blue100: "#009ACE",
  blue75: "#40B4DB",
  blue50: "#7FCCE6",
  gold: "#BE933C",
  green100: "#6BCC16",
  green75: "#90D950",
  green50: "#B4E58A",
  yellow: "#FFE879",
  white: "#FFFFFF",
  turquoise: "#26C0AC",
  emerald100: "#257F49",
  emerald75: "#2DA35A",
  emerald50: "#52C075",
};
