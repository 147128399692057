import { baseTheme } from "@viper-head/ui-kit/themes";
import { Spinner } from "@viper-head/ui-kit/themes/skins/viper/Spinner";
import {
  createSkinRegistry,
  UIKitSkinsNamespace,
} from "@viper-head/ui-kit/core";

export const buildTheme = () => ({
  ...baseTheme,
  name: "viper",
  skins: createSkinRegistry(UIKitSkinsNamespace, {
    Spinner,
  }),
});
