export type JSSRuleFunction = (...args: any[]) => any;
export type JSSRule = any;
export interface JSSObjectRule {
  [index: string]: JSSRule;
}

export function mergeRules(target: JSSRule, source: JSSRule): JSSRule {
  if (isObject(target) && isObject(source)) {
    return mergeObjects(target, source);
  }

  if (typeof target === "function" && isObject(source)) {
    return isObjectEmpty(source)
      ? target
      : mergeFunctionWithObject(target, source);
  }

  if (isObject(target) && typeof source === "function") {
    return mergeObjectWithFunction(target, source);
  }

  if (typeof target === "function" && typeof source === "function") {
    return mergeFunctions(target, source);
  }

  return source;
}

export const isObject = (item: any): item is JSSObjectRule =>
  item !== null && typeof item === "object" && !Array.isArray(item);

export const isObjectEmpty = (item: object) => Object.keys(item).length === 0;

export function mergeObjects(
  target: JSSObjectRule,
  source: JSSObjectRule,
): JSSObjectRule {
  const copy = { ...target };

  Object.keys(source).forEach((key) => {
    copy[key] = mergeRules(target[key], source[key]);
  });

  return copy;
}

export const mergeFunctionWithObject =
  (fn: JSSRuleFunction, object: JSSObjectRule) =>
  (...args: any[]) => {
    const result = fn(...args);
    return mergeRules(result, object);
  };

export const mergeObjectWithFunction =
  (object: JSSObjectRule, fn: JSSRuleFunction) =>
  (...args: any[]) => {
    const result = fn(...args);
    return mergeRules(object, result);
  };

export const mergeFunctions =
  (fnA: JSSRuleFunction, fnB: JSSRuleFunction) =>
  (...args: any[]) => {
    const resultA = fnA(...args);
    const resultB = fnB(...args);
    return mergeRules(resultA, resultB);
  };
