import type { Typography } from "#types";

const title1 = {
  fontSize: 32,
  lineHeight: "40px",
  fontWeight: 400,
} as unknown as Typography;

const title2 = {
  fontSize: 18,
  lineHeight: "24px",
  fontWeight: 500,
} as unknown as Typography;

const heading = {
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: 500,
} as unknown as Typography;

const body = {
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: 400,
} as unknown as Typography;

const labelMedium = {
  fontSize: 12,
  lineHeight: "16px",
  fontWeight: 500,
} as unknown as Typography;

const labelRegular = {
  fontSize: 12,
  lineHeight: "16px",
  fontWeight: 400,
} as unknown as Typography;

const overline = {
  fontSize: 12,
  lineHeight: "16px",
  fontWeight: 700,
  textTransform: "uppercase",
  letterSpacing: "1.2px",
} as unknown as Typography;

export const typography = {
  fontFamily: "Roboto, sans-serif",
  title1,
  title2,
  heading,
  body,
  overline,
  labelMedium,
  labelRegular,
};
