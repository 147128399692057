const styles = {
  "@global": {
    html: {
      height: "100%",
      boxSizing: "border-box",
      fontSmoothing: "antialiased",
      touchAction: "manipulation",
    },

    "*, *::before, *::after": {
      boxSizing: "inherit",
    },

    body: {
      height: "100%",
      "-webkit-user-select": "none",
      userSelect: "none",
      tapHighlightColor: "transparent",
      overflow: "hidden",
    },

    "#root": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },

    "#root > main": {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      height: "calc(100% - 40px)",
    },

    "input, select, textarea, button": {
      fontFamily: "inherit",
    },
  },
};

export default styles;
