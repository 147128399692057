export interface QaAttributes {
  [key: string]: string | boolean | number | undefined;
}

interface DataAttributes {
  [key: string]: string;
}

export interface WithQAAttributes {
  /** If you want to add some qa attributes to your root component */
  qaAttributes?: QaAttributes;
}

function buildQaAttributes(attributes?: QaAttributes) {
  if (attributes === undefined) {
    return undefined;
  } else {
    const result: DataAttributes = {};

    for (const [key, value] of Object.entries(attributes)) {
      if (value !== undefined) {
        result[`data-qa-${key}`] = value.toString();
      }
    }

    return result;
  }
}

export default buildQaAttributes;
