import type { Shadow } from "#types";
import { typography } from "../common/typography.ts";
import { palette } from "../common/palette.ts";

export const unit = 8;

const baseTheme = {
  typography,
  zIndex: {
    base: 1,
    upper: 10,
    overlay: 20,
    ontop: 30,
  },
  unit,
  palette,

  shadows: {
    overlay: [0, 2, 6, 0, "rgba(0, 0, 0, 0.24)"] as Shadow,
    modal: [0, 2, 8, 0, "rgba(0, 0, 0, 0.48)"] as Shadow,
  },

  borderRadius: 4,
  borderWidth: 1,
};

export default baseTheme;
