import { defineMessages } from "react-intl";

export default defineMessages({
  text: {
    id: "bootstrap.loading",
    description: {
      text: "Text displayed when the app is loading",
    },
    defaultMessage: "Loading...",
  },
  error: {
    id: "bootstrap.error",
    description: {
      text: "Text displayed when the app encounters an error",
    },
    defaultMessage: "An error occurred. Please try again later.",
  },
});
