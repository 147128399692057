import { createUseStyles } from "react-jss";
import type { Theme } from "@viper-head/ui-kit/types";

const styles = ({ unit, palette: { gray5 } }: Theme) => ({
  background: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: gray5,
  },

  text: {
    fontWeight: 400,
    letterSpacing: "0px",
    textAlign: "center",
    paddingTop: unit,
  },
});

export const useStyles = createUseStyles(styles);
