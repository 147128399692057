import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { IntlProvider } from "react-intl";
import { Provider } from "jotai";
import { Overlay } from "./components/Overlay/index.tsx";
import { ThemeProvider } from "react-jss";
import { buildTheme } from "./themes.ts";
import { jss } from "react-jss";
import normalize from "normalize-jss";
import styles from "./styles.ts";

const fetchAuthRedirect = async () => {
  const response = await fetch("/auth" + window.location.search);

  return await response.json();
};

const defaultLocale = "en";

const App = () => {
  const [_, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { redirectUrl } = await fetchAuthRedirect();
        window.location.href = redirectUrl;
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    jss.createStyleSheet(normalize).attach();
    jss.createStyleSheet(styles).attach();
  }, []);

  return (
    <Provider>
      <IntlProvider locale={defaultLocale}>
        <ThemeProvider theme={buildTheme()}>
          <Overlay showError={error} />
        </ThemeProvider>
      </IntlProvider>
    </Provider>
  );
};

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(<App />);
