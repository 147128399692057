import type { Skins, SkinRegistry, Theme } from "#types";

export const UIKitSkinsNamespace = "uikit";

export const composeSkinRegistries = (...registries: SkinRegistry[]) =>
  Object.assign({}, ...registries);

export const updateTheme = (theme: Theme, fields: Partial<Theme>) => ({
  ...theme,
  ...fields,
});

export const createSkinRegistry = (namespace: string, skins: Skins) => ({
  [namespace]: skins,
});

export const extendWithExtraSkins = (
  theme: Theme,
  registries: SkinRegistry[],
) => {
  const skins = composeSkinRegistries(theme.skins, ...registries);
  return updateTheme(theme, { skins });
};
