import type { SpinnerStyles } from "#atoms";
import type { Theme } from "#types";

export const Spinner: SpinnerStyles = ({ palette }: Theme) => ({
  "@keyframes xl-animation": {},
  "@keyframes s-animation": {},
  primary: {
    fill: palette.blue100,
  },
  secondary: {
    fill: palette.blue100,
  },
  labelContainer: {},
  container: {},
  xl: {},
  s: {},
});
