import type { ComponentType } from "react";
import { withStyles, type Styles } from "react-jss";

import type { GetProps, ComponentStyles, LocalSkinStyles, Theme } from "#types";

import { mergeRules } from "./helpers/index.ts";
import { UIKitSkinsNamespace } from "./registry.ts";

type Merger = (theme: Theme) => any;

const createMergingStyles = (merger: Merger) => (theme: Theme) => {
  const mergedStyles = merger(theme);

  return typeof mergedStyles === "function"
    ? mergedStyles(theme)
    : mergedStyles;
};

export const injectSkinnedSheet = <
  ClassNames extends string | number | symbol,
  S extends Styles<ClassNames> | ((theme: Theme) => Styles<ClassNames>),
  TComponent extends ComponentType<GetProps<TComponent>>,
>(
  styles: S,
  component: TComponent,
  skinStyles?: LocalSkinStyles<ComponentStyles<S>>,
  namespace?: string,
  options?: any,
) => {
  let merger: Merger;

  if (skinStyles !== undefined) {
    merger = (theme: Theme) =>
      theme.name in skinStyles
        ? mergeRules(styles, skinStyles[theme.name])
        : styles;
  } else {
    if (component.displayName === undefined || component.displayName === "") {
      throw new Error(
        "You should set `displayName` for you component to use injectSkinnedSheet.",
      );
    }
    merger = (theme: Theme) => {
      const skinRegistry = theme.skins[namespace ?? UIKitSkinsNamespace];
      return skinRegistry !== undefined &&
        component.displayName !== undefined &&
        component.displayName in skinRegistry
        ? mergeRules(styles, skinRegistry[component.displayName])
        : styles;
    };
  }

  const newStyles = createMergingStyles(merger);
  const newOptions = { inject: ["classes"], ...options };

  return withStyles(newStyles, newOptions)(component);
};

// Could not use .bind due lack of proper TS support for this complex case
export const makeNamespacedInjecter =
  (namespace: string) =>
  <
    ClassNames extends string | number | symbol,
    S extends
      | Styles<ClassNames, any, any>
      | ((theme: Theme) => Styles<ClassNames, any, any>),
    TComponent extends ComponentType<GetProps<TComponent>>,
  >(
    styles: S,
    component: TComponent,
    options?: any,
  ) =>
    injectSkinnedSheet<ClassNames, S, TComponent>(
      styles,
      component,
      undefined,
      namespace,
      options,
    );
