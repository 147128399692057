import type { FunctionComponent, ReactNode } from "react";
import classNames from "classnames";
import type { WithStylesProps } from "react-jss";

import buildQaAttributes, {
  type QaAttributes,
  type WithQAAttributes,
} from "#build-qa-attributes";
import type { ComponentStyles } from "#types";

import { injectUIKitSkinnedSheet } from "#atoms/utils";

interface Props extends WithStylesProps<typeof styles>, WithQAAttributes {
  /** Additional className */
  className?: string;
  /** **DEPRECATED** */
  label?: ReactNode;
  /** There are available variants of your Spinner: *primary* and *secondary* */
  variant?: "primary" | "secondary";
  /** Prop configures size of Spinner with 'xl' by default */
  size?: "s" | "xl";
  /** If you need container qa atributes */
  qaAttributesContainer?: QaAttributes;
}

export const Spinner: FunctionComponent<Props> = ({
  className,
  qaAttributes,
  qaAttributesContainer,
  variant = "primary",
  label,
  classes,
  size = "xl",
}) => (
  <div
    className={classes.container}
    {...buildQaAttributes(qaAttributesContainer)}
  >
    <div
      className={classNames(className, {
        [classes.xl]: size === "xl",
        [classes.s]: size === "s",
        [classes.primary]: variant === "primary",
        [classes.secondary]: variant === "secondary",
      })}
      {...buildQaAttributes(qaAttributes)}
    >
      {size === "xl" ? (
        <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg">
          <g fillRule="evenodd">
            <path
              d="M36.74 8.514A19.908 19.908 0 0 0 26.082 4.1V.082A23.894 23.894 0 0 1 39.58 5.674l-2.84 2.84z"
              opacity=".125"
            />
            <path
              d="M43.958 22.058A19.908 19.908 0 0 0 39.545 11.4l2.84-2.84a23.894 23.894 0 0 1 5.59 13.498h-4.017z"
              opacity=".25"
            />
            <path
              d="M39.486 36.74A19.908 19.908 0 0 0 43.9 26.082h4.017a23.894 23.894 0 0 1-5.592 13.498l-2.84-2.84z"
              opacity=".375"
            />
            <path
              d="M25.942 43.958A19.908 19.908 0 0 0 36.6 39.545l2.84 2.84a23.894 23.894 0 0 1-13.498 5.59v-4.017z"
              opacity=".5"
            />
            <path
              d="M11.26 39.486A19.908 19.908 0 0 0 21.918 43.9v4.017A23.894 23.894 0 0 1 8.42 42.326l2.84-2.84z"
              opacity=".625"
            />
            <path
              d="M4.042 25.942A19.908 19.908 0 0 0 8.455 36.6l-2.84 2.84a23.894 23.894 0 0 1-5.59-13.498h4.017z"
              opacity=".75"
            />
            <path
              d="M8.514 11.26A19.908 19.908 0 0 0 4.1 21.918H.082A23.894 23.894 0 0 1 5.674 8.42l2.84 2.84z"
              opacity=".875"
            />
            <path
              d="M22.058 4.042A19.908 19.908 0 0 0 11.4 8.455l-2.84-2.84A23.894 23.894 0 0 1 22.058.025v4.017z"
              opacity="1"
            />
          </g>
        </svg>
      ) : (
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
          <g fillRule="evenodd">
            <path
              d="M7,14.9291111 C5.18038198,14.6688352 3.58650747,13.7087669 2.49974457,12.3302745 L4.25730955,11.3155439 C4.96268318,12.111202 5.91789056,12.6803253 7,12.8999819 L7,14.9291111 L7,14.9291111 Z"
              opacity="0.167"
            />
            <path
              d="M1.4983706,10.5990169 C1.17685697,9.795452 1,8.91837958 1,8 C1,7.08162042 1.17685697,6.204548 1.4983706,5.40098305 L3.25616003,6.41584325 C3.08999677,6.91365544 3,7.44631778 3,8 C3,8.55368222 3.08999677,9.08634456 3.25616003,9.58415675 L1.4983706,10.5990169 L1.4983706,10.5990169 Z"
              opacity="0.334"
            />
            <path
              d="M2.49974457,3.66972551 C3.58650747,2.29123307 5.18038198,1.33116482 7,1.07088886 L7,3.10001812 C5.91789056,3.31967474 4.96268318,3.88879797 4.25730955,4.68445612 L2.49974457,3.66972551 L2.49974457,3.66972551 Z"
              opacity="0.5"
            />
            <path
              d="M9,1.07088886 C10.819618,1.33116482 12.4134925,2.29123307 13.5002554,3.66972551 L11.7426904,4.68445612 C11.0373168,3.88879797 10.0821094,3.31967474 9,3.10001812 L9,1.07088886 L9,1.07088886 Z"
              opacity="0.667"
            />
            <path
              d="M14.5016294,5.40098305 C14.823143,6.204548 15,7.08162042 15,8 C15,8.91837958 14.823143,9.795452 14.5016294,10.5990169 L12.74384,9.58415675 C12.9100032,9.08634456 13,8.55368222 13,8 C13,7.44631778 12.9100032,6.91365544 12.74384,6.41584325 L14.5016294,5.40098305 L14.5016294,5.40098305 Z"
              opacity="0.884"
            />
            <path
              d="M13.5002554,12.3302745 C12.4134925,13.7087669 10.819618,14.6688352 9,14.9291111 L9,12.8999819 C10.0821094,12.6803253 11.0373168,12.111202 11.7426904,11.3155439 L13.5002554,12.3302745 L13.5002554,12.3302745 Z"
              opacity="1"
            />
          </g>
        </svg>
      )}
    </div>
    {label !== undefined && (
      <div className={classes.labelContainer}>{label}</div>
    )}
  </div>
);

Spinner.displayName = "Spinner";

const styles = {
  "@keyframes xl-animation": {
    0: {
      transform: "rotate(0deg)",
    },
    "12.5%": {
      transform: "rotate(45deg)",
    },
    "25%": {
      transform: "rotate(90deg)",
    },
    "37.5%": {
      transform: "rotate(135deg)",
    },
    "50%": {
      transform: "rotate(180deg)",
    },
    "62.5%": {
      transform: "rotate(225deg)",
    },
    "75%": {
      transform: "rotate(270deg)",
    },
    "87.2%": {
      transform: "rotate(315deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  "@keyframes s-animation": {
    0: {
      transform: "rotate(0deg)",
    },
    "16.7%": {
      transform: "rotate(60deg)",
    },
    "33.4%": {
      transform: "rotate(120deg)",
    },
    "50%": {
      transform: "rotate(180deg)",
    },
    "66.7%": {
      transform: "rotate(240deg)",
    },
    "88.4%": {
      transform: "rotate(300deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  xl: {
    width: 48,
    height: 48,
    animation: "$xl-animation 1s step-start infinite",
  },
  s: {
    width: 16,
    height: 16,
    animation: "$s-animation 0.75s step-start infinite",
  },
  labelContainer: {
    paddingTop: 10,
  },
  primary: {},
  secondary: {},
};

export type SpinnerStyles = ComponentStyles<typeof styles>;

export default injectUIKitSkinnedSheet(styles, Spinner);
