import { useStyles } from "./styles.ts";
import { FormattedMessage } from "react-intl";
import messages from "./messages.ts";
import { Spinner } from "@viper-head/ui-kit/atoms";

export const Overlay = ({ showError }: { showError: boolean }) => {
  const classes = useStyles();
  return (
    <div className={classes.background}>
      {showError ? (
        <div className={classes.text}>
          <FormattedMessage {...messages.error} />
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
